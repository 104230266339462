/* colors */
:root {
  --background: #ffffff;
  --c-background: linear-gradient(to right, #00a3df, #036888);
  --pri-t: #000000;
  --sec-t: #ffffff;
  --tri-t: #999999;
  --pri-c: #0286b4;
  --sec-c: #00a4e1;
  --navb-c: #000000;

  /* typography */
  --font: "Inter", sans-serif;

  /* Web Size */
  /* h1 weight/Bold lineheight/80*/
  --wh1: 72px;
  /* --mh1: 56px; */
  --mh1: 47px;
  /* h2 weight/Bold lineheight/64*/
  --wh2: 56px;
  --mh2: 40px;
  /* h3 weight/Bold lineheight/48*/
  --wh3: 40px;
  --mh3: 23px;
  /* h4 weight/Bold lineheight/32*/
  --wh4: 24px;
  /* --mh4: ; */
  /* h5 weight/Regular lineheight/24*/
  --wh5: 16px;
  /* --mh5: ; */
  /* label weight/Bold lineheight/auto*/
  --wlabel: 12px;
  --mlabel: 12px;
  /* text-big weight/Regular lineheight/32*/
  --wtb: 20px;
  --mtb: 20px;
  /* text weight/Bold lineheight/24*/
  --wt: 16px;
  --mt: 12px;
  /* text-Gray...input text weight/Regular lineheight/24*/
  --wtg: 16px;
  --mtg: 16px;
  /* text-Gray weight/Bold lineheight/24*/
  --wts: 12px;
  --mts: 12px;
  /* button text weight/Bold lineheight/24*/
  --wb: 16px;
  --mb: 24px;

  /* Grid Layout */
  --wcol: 10% 1fr 5% 1fr 10%;
  --mcol: 5% auto 5%;
  /* Section Padding */
  --wpad: 50px 0;
  --mpad: 10px 0;

  --brad: 10px;
}
* {
  transition: all 0.3s ease-in-out;
  margin: 0;
}

body {
  width: 100vw;
  background: var(--background);
  font-family: "DM Sans", sans-serif;
  margin: 0;
}


/* STRIPE */
.stripe-form {
  /* width: 60vw; */
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
}
.stripe-input {
  border-radius: 6px;
  margin-bottom: 6px;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  max-height: 44px;
  font-size: 16px;
  width: 100%;
  background: white;
  box-sizing: border-box;
}
.result-message {
  line-height: 22px;
  font-size: 16px;
}
.result-message a {
  color: rgb(89, 111, 214);
  font-weight: 600;
  text-decoration: none;
}
.hidden {
  display: none;
}
#card-error {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  margin-top: 12px;
  text-align: center;
}
#card-element {
  border-radius: 4px 4px 0 0;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  max-height: 44px;
  width: 100%;
  background: white;
  box-sizing: border-box;
}
#payment-request-button {
  margin-bottom: 32px;
}
/* Buttons and links */
.stripe-button {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 0 0 4px 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}
button:hover {
  filter: contrast(115%);
}
.stripe-button:disabled {
  opacity: 0.5;
  cursor: default;
}
/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}
.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}
.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}
.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}
@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
  }
}

/* Big Screens Up */
@media screen and (max-width: 8000px) {
  /* Font Sizes */
  h1 {
    font-size: var(--wh1);
    font-weight: Bold;
    line-height: 80px;
  }
  h2 {
    font-size: var(--wh2);
    font-weight: Bold;
    line-height: 64px;
  }
  h3 {
    font-size: var(--wh3);
    font-weight: Bold;
    line-height: 48px;
  }
  h4 {
    font-size: var(--wh4);
    font-weight: Bold;
    line-height: 32px;
  }
  h5 {
    font-size: var(--wh5);
    font-weight: normal;
    line-height: 32px;
  }
  p,
  .footer-anchor {
    font-size: var(--wt);
    font-weight: Bold;
    line-height: 24px;
  }
  .text-big {
    font-size: 26px;
    font-weight: lighter;
    line-height: 32px;
    font-family: "Roboto", sans-serif;
  }
  .text-gray {
    font-size: var(--wtg);
    color: var(--tri-t);
    font-weight: normal;
  }
  a {
    text-decoration: none;
  }
  i,
  .ant-badge {
    color: var(--pri-c);
  }
  /* FIX THIS */
  button,
  .hero-link,
  .footer-link,
  input[type="button"],
  input[type="submit"],
  input[type="reset"] {
    font-size: var(--wb);
    font-weight: bold;
    line-height: 24px;
    background-color: var(--pri-c);
    color: var(--sec-t);
    width: 170px;
    height: 48px;
    border: none;
    border-radius: var(--brad);
    cursor: pointer;
    text-decoration: none;
    margin: 4px 2px;
  }
  a:hover {
    color: var(--sec-c);
  }
  button:hover,
  .hero-link:hover,
  .footer-link:hover,
  input[type="button"]:hover,
  input[type="submit"]:hover,
  input[type="reset"]:hover {
    background-color: var(--sec-c);
  }
  a:active,
  button:active,
  .hero-link:active,
  .footer-link:active,
  input[type="button"]:active,
  input[type="submit"]:active,
  input[type="reset"]:active {
    background: var(--sec-t);
    -webkit-box-shadow: inset 0px 0px 5px #c1c1c1;
    -moz-box-shadow: inset 0px 0px 5px #c1c1c1;
    box-shadow: inset 0px 0px 5px #c1c1c1;
    outline: none;
  }
  .modalIconBtn,
  .modalIconBtn:hover,
  .modalIconBtn:active {
    background-color: var(--background);
    width: 160px;
    height: 128px;
    padding: 0;
  }
  .footer-anchor,
  .footer-anchor:hover,
  .footer-anchor:focus,
  .footer-anchor:active {
    text-decoration: none;
    color: inherit;
  }
  img {
    width: 100%;
  }

  /* Modal Code */
  .centered {
    width: 100%;
    height: 100%;
  position: fixed;
  display: flex;
  align-content: center;
  justify-content: center;
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
}
  .modal-container {

    border: 1px solid black;
    max-width: 80%;
    max-height: 85%;
    overflow-y: scroll;
    align-self: center;


  height: 250px;
  background: var(--sec-c);
  z-index: 10;
    border-radius: var(--brad);
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);

  }
  /* .modal.active {
    transform: translate(-50%, -50%) scale(1);
  } */
  .modal-header {
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid black;
  }
  .modal-container .title {
    font-size: var(--wh3);
    font-weight: bold;
    color: var(--sec-t);
  }
  .modal-header .close-button {
    cursor: pointer;
    border: none;
    outline: none;
    background: none;
    font-size: 1.25rem;
    font-weight: bold;
    width: auto;
  }
  .modal-text-title {
    font-size: var(--wh4);
    color: var(--sec-t);
  }
  .modal-body {
    font-size: var(--wh5);
    padding: 10px 15px;
  }
  .modal-text-bold {
    font-weight: bold;
  }
  .overlay {
    /* position: fixed;
    opacity: 0;
    transition: 200ms ease-in-out;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    to click on button when class is not active
    pointer-events: none;
    z-index: 2; */

     background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
  }
  /* #overlay.active {
    opacity: 1;
    pointer-events: all;
  } */

  /* Header and Nav */
  header {
    z-index: 3;
    width: 100%;
    position: sticky;
    top: 0;
  }
  .header-container {
    /* width: 100%; */
    height: auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 5px 10%;
    background: var(--navb-c);
  }
  .logo {
    cursor: pointer;
    width: 10%;
  }
  .toggle-button {
    color: var(--pri-c);
    display: none;
    position: absolute;
    flex-direction: column;
    justify-content: space-between;
    align-self: flex-end;
    /* width: 30px;
    height: 21px; */
    width: auto;
    height: auto;
  }

  .navbar-links-middle {
  }
  .navbar-links-right {
  }
  .navbar-links-middle.active,
  .navbar-links-right.active {
    display: flex;
  }

  .nav-links {
    margin: 0;
    padding: 0;
    display: flex;
  }
  .nav-links li {
    list-style: none;
    display: inline-block;
    padding: 0px 20px;
  }

  .header-container a,
  .header-container .ant-badge {
    color: var(--sec-c);
    font-size: var(--wh4);
  }

  .header-container a:hover,
  .header-container .ant-badge:hover {
    color: var(--pri-c);
  }

  .header-container a:active,
  .header-container .ant-badge:active {
    background: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    outline: none;
    color: var(--sec-t);
  }

  .slider img,
  .card-image {
    height: 450px;
    object-fit: contain;
  }

  /* Hero Section */
  #hero {
    display: grid;
    width: 100vw;
    height: auto;
    grid-template-columns: var(--wcol);
    grid-template-rows: repeat(4, auto);
    padding: var(--wpad);
  }
  .hero-title {
    grid-column: 2/3;
    grid-row: 1/2;
    align-self: center;
  }
  .hero-text {
    grid-column: 2/3;
    grid-row: 2/3;
    font-size: 22px;
    font-weight: normal;
    margin-top: 0;
    align-self: start;
  }
  .hero-action {
    grid-column: 2/3;
    grid-row: 3/4;
    justify-self: start;
    display: flex;
    flex-direction: column;
  }
  .hero-action div {
    align-self: center;
  }
  .hero-link {
    text-align: center;
    line-height: 48px;
  }
  .icons {
    padding-top: 50px;
  }

  i.fab {
    display: inline-block;
    border-radius: 60px;
    box-shadow: 0px 0px 2px #888;
    padding: 0.5em 0.6em;
  }
  i.fab:hover {
    color: var(--sec-c);
  }
  .icon {
    margin-left: 5px;
    margin-right: 5px;
  }
  .hero-logo {
    grid-column: 4/5;
    grid-row: 1/3;
    align-self: center;
  }
  .fa-arrow-down {
    grid-column: 3/4;
    grid-row: 4/5;
    justify-self: center;
    align-self: center;
    padding-top: 50px;
  }

  /* About Section */
  #about {
    display: grid;
    grid-template-columns: 10% 0.6fr 5% 0.4fr 10%;
    grid-template-rows: repeat(3, auto);
    padding: var(--wpad);
  }
  .about-image {
    grid-column: 2/3;
    grid-row: 1/4;
    align-self: center;
  }
  .about-title {
    grid-column: 4/5;
    grid-row: 1/2;
    align-self: center;
    margin-top: 0;
  }
  .about-text {
    grid-column: 4/5;
    grid-row: 2/3;
    align-self: center;
    /* change for smaller Screen */
    padding-right: 100px;
  }
  .about-action {
    grid-column: 4/5;
    grid-row: 3/4;
    align-self: center;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .action-web {
    padding-right: 25px;
  }
  .action-mob {
    display: none;
  }
  .fa-play {
    color: var(--sec-t);
  }
  .about-action-web p {
    padding-left: 20px;
    align-self: center;
  }

  /* Testimony Section */
  .testimony {
    background: var(--c-background);
    display: grid;
    /* grid-template-columns: var(--wcol);
    grid-template-rows: repeat(4, auto); */
    grid-template-columns: 10% auto 10%;
    grid-template-rows: repeat(4, auto);
    padding: var(--wpad);
    height: auto;
  }
  .test-title {
    color: var(--sec-t);
    grid-column: 2/3;
    grid-row: 1/2;
  }
  .test-text {
    color: var(--pri-t);
    grid-column: 2/3;
    grid-row: 2/3;
  }
  .test-clients {
    grid-column: 2/3;
    grid-row: 3/5;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    /* grid-template-columns: 40% 60%;
  grid-template-rows: 70% 15% 15%; */
  }
  .client {
    display: grid;
    grid-template-columns: 20% 30% 50%;
    grid-template-rows: 70% 15% 15%;
    /* column-gap: 1rem; */
    /* grid-template-columns: 40% 60%;
    grid-template-rows: 70% 15% 15%; */
  }
  .client-pic {
    width: 75%;

    grid-column: 1/2;
    grid-row: 1/4;
    justify-self: start;
    align-self: center;
  }
  .client-text {
    color: var(--sec-t);
    grid-column: 2/3;
    grid-row: 1/2;
    align-self: end;
    margin: 0;
    padding-right: 40px;
  }
  .client-name {
    grid-column: 2/3;
    grid-row: 2/3;
    margin: 0;
    align-self: end;
    color: var(--pri-t);
  }
  .client-company {
    grid-column: 2/3;
    grid-row: 3/4;
    align-self: start;
    margin: 0;
    color: var(--pri-t);
  }
  .client-link{
    color: var(--sec-t);
 
  }
  .c-top,
  .c-bottom {
    padding: 25px 0 25px 0;
  }
  .client-web {
    /* grid-column: 4/5;
    grid-row: 1/5; */
    grid-column: 3/4;
    grid-row: 1/4;
    align-self: center;
    width: 100%;
  }
  .client-mob{
    display: none;
  }

  .gallery-testimony {
    grid-column: 2/3;
    grid-row: 3/4;
	padding: 1rem;
	display: grid;
	grid-template-columns: repeat(10, 80vw);
	grid-template-rows: 1fr;
	grid-column-gap: 1rem;
	grid-row-gap: 1rem;
	overflow: scroll;
	height: auto;
	scroll-snap-type: both mandatory;
	scroll-padding: 1rem;
}
.active {
	scroll-snap-type: unset;
}

.client-list {
	scroll-snap-align: center;
	display: inline-block;
	border-radius: 3px;
	font-size: 0;
}

  /* Features Section */
  .feature {
    display: grid;
    grid-template-columns: var(--wcol);
    grid-template-rows: repeat(4, auto);
    padding: var(--wpad);
  }
  .feat-title {
    grid-column: 2/5;
    grid-row: 1/2;
    justify-self: center;
  }
  .feat-l {
    grid-column: 2/3;
    grid-row: 2/3;
    justify-self: center;
  }
  .feat-r {
    grid-column: 4/5;
    grid-row: 2/3;
    justify-self: center;
  }
  .feat-b {
    grid-column: 2/5;
    grid-row: 3/4;
    justify-self: center;
  }
  .feat-icons {
    height: auto;
    z-index: 1;
    grid-column: 2/5;
    grid-row: 4/5;
    align-self: center;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(6, 1fr);
  }
  .iandt {
    align-self: center;
    justify-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .ionly :hover {
    color: var(--sec-c);
  }
  .ionly:active {
    color: var(--pri-c);
  }
  .feat-item-text {
    text-align: center;
    margin: 0;
  }
  /* Benefits Section */
  .benefit {
    background: var(--c-background);
    display: grid;
    grid-template-columns: 10% 0.65fr 0.35fr 5% 1fr 10%;
    grid-template-rows: 10% 80% 10%;
    padding: var(--wpad);
  }
  .bene-pic {
    grid-column: 1/4;
    grid-row: 1/4;
  }
  .bene-container {
    height: 90%;
    grid-column: 3/6;
    grid-row: 1/4;
    align-self: center;
    border-radius: var(--brad);
    display: grid;
    grid-template-columns: 10% 42.5% 42.5% 5%;
    grid-template-rows: 40% 30% 30%;
    align-items: start;
    background-color: var(--background);
  }
  .bene {
    overflow: none;
    display: grid;
    grid-column: 1fr;
    grid-row: 20% 80%;
    /* padding-right: 100px; */
  }
  .bene h4 {
    margin: 0;
  }
  .bene-container-title {
    grid-column: 2/4;
    grid-row: 1/2;
    align-self: center;
  }
  .bene-container .item1 {
    grid-column: 2/3;
    grid-row: 2/3;
  }
  .bene-container .item2 {
    grid-column: 3/4;
    grid-row: 2/3;
  }
  .bene-container .item3 {
    grid-column: 2/3;
    grid-row: 3/4;
  }
  .bene-container .item4 {
    grid-column: 3/4;
    grid-row: 3/4;
  }
  .bene p {
    align-self: center;
    margin: 0;
    padding-right: 100px;
  }

  /* CTA Section */
  #cta {
    background: var(--c-background);
    display: grid;
    /* grid-template-columns: var(--wcol); */
    grid-template-columns: 10%  auto 25% auto 10%;

    grid-template-rows: repeat(4, auto);
    padding: var(--wpad);
  }
  .call-title {
    grid-column: 3/4;
    grid-row: 1/2;
    justify-self: center;
    color: var(--sec-t);
  }
  .call-text {
    grid-column: 2/5;
    grid-row: 2/3;
    justify-self: center;
    color: var(--sec-t);
  }
  .forms {
    grid-column: 2/5;
    grid-row: 3/4;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    column-gap: 50px;
    grid-template-rows: repeat(3, 1fr);
    row-gap: 25px;
  }
  .forms input {
    border-radius: var(--brad);
    border: none;
    padding-left: 20px;
  }
  .form-input1 {
    grid-column: 1/5;
    grid-row: 1/2;
  }
  .form-input2 {
    grid-column: 5/9;
    grid-row: 1/2;
  }
  .form-input3 {
    grid-column: 1/5;
    grid-row: 2/3;
  }
  .form-input4 {
    grid-column: 5/9;
    grid-row: 2/3;
  }
  .form-button {
    background-color: var(--pri-t);
    grid-column: 4/6;
    grid-row: 3/4;
    justify-self: center;
  }

  /* Footer */
  footer {
    background-color: var(--navb-c);
    color: var(--sec-t);
    display: grid;
    grid-template-columns: 10% 0.5fr 0.5fr 5% 0.5fr 0.5fr 10%;
    column-gap: 1%;
    grid-template-rows: 90% 10%;
    padding: var(--wpad);
  }
  .foot1 {
    grid-column: 2/3;
    grid-row: 1/2;
    justify-self: start;
    align-self: start;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: repeat(3, auto);
    row-gap: 20px;
  }
  .footer-logo {
    grid-column: 1/2;
    grid-row: 1/2;
    justify-self: center;
    align-self: center;
  }
  .footer-link {
    line-height: 48px;
    text-align: center;
    grid-column: 1/2;
    grid-row: 2/3;
    justify-self: center;
    align-self: center;
  }
  .footer-icons {
    grid-column: 1/2;
    grid-row: 3/4;
    justify-self: center;
    align-self: center;
  }
  .hero-action {
    grid-column: 2/3;
    grid-row: 3/4;
    justify-self: start;
    display: flex;
    flex-direction: column;
  }
  .hero-action div {
    align-self: center;
  }
  .hero-link {
    text-align: center;
    line-height: 48px;
  }
  .foot2 {
    grid-column: 3/4;
    grid-row: 1/2;
    justify-self: start;
    align-self: start;
  }
  .foot3 {
    grid-column: 5/6;
    grid-row: 1/2;
    justify-self: start;
    align-self: start;
  }
  .foot4 {
    grid-column: 6/7;
    grid-row: 1/2;
    justify-self: start;
    align-self: start;
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-template-rows: repeat(3, auto);
  }
  .f4-title {
    grid-column: 1/3;
    grid-row: 1/2;
  }
  .f4-pic1 {
    grid-column: 1/2;
    grid-row: 2/3;
  }
  .f4-pic2 {
    grid-column: 2/3;
    grid-row: 2/3;
  }
  .f4-pic3 {
    grid-column: 3/4;
    grid-row: 2/3;
  }
  .f4-pic4 {
    grid-column: 1/2;
    grid-row: 3/4;
  }
  .f4-pic5 {
    grid-column: 2/3;
    grid-row: 3/4;
  }
  .f4-pic6 {
    grid-column: 3/4;
    grid-row: 3/4;
  }
  .foot-dis {
    grid-column: 3/6;
    grid-row: 2/3;
    justify-self: center;
    align-self: end;
  }
  footer h4 {
    margin-top: 0;
  }
  footer img {
    width: 50%;
  }

  .productCard {
    display: grid;
    grid-template-columns: 75% 25%;
    grid-template-rows: 50% 50%;
    align-items: center;
  }
  .productCard-title {
    grid-column: 1/3;
    grid-row: 1/2;
  }
  .productCard-description {
    grid-column: 1/2;
    grid-row: 2/3;
  }
  .productCard-price {
    grid-column: 2/3;
    grid-row: 2/3;
  }
}
/* Desktops */
/* @media screen and (max-width: 1920px) {
} */
/* Laptops */
@media screen and (max-width: 1440px) {
  .head-wimage {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  .about-text {
    padding-right: 0;
  }
  .bene-container {
    grid-template-rows: 30% 35% 35%;
    height: auto;
    padding: 25px 0;
  }
  .bene-container-title {
    padding: 20px 0;
    margin: 0;
  }
  .bene p {
    padding-right: 15px;
  }
}
/* Tablet Devices */
@media screen and (max-width: 1024px) {
  h1 {
    font-size: var(--mh1);
    font-weight: Bold;
    line-height: 60px;
  }
  h2 {
    font-size: var(--mh2);
    font-weight: Bold;
    line-height: 48px;
  }
  h3 {
    font-size: var(--mh3);
    font-weight: Bold;
    line-height: 40px;
  }
  h4 {
    font-size: var(--mh4);
    font-weight: Bold;
    line-height: 32px;
  }
  h5 {
    font-size: var(--mh5);
    font-weight: normal;
    line-height: 24px;
  }
  p {
    font-size: var(--mt);
    font-weight: Bold;
    line-height: 24px;
  }
  .text-big {
    font-size: 26px;
    font-weight: lighter;
    line-height: 32px;
    font-family: "Roboto", sans-serif;
  }

  /* Header and Nav */

  header {
    display: flex;
  }
  .logo {
    padding: 10px 0;
  }
  .toggle-button
  /* ,.hero-cta-button  */ {
    display: flex;
  }
  .navbar-links-middle,
  .navbar-links-right {
    display: none;
    width: 100%;
  }
  .navbar {
    flex-direction: column;
    align-items: flex-start;
  }
  .nav-links {
    width: 100%;
    flex-direction: column;
  }
  .navbar-links li {
    text-align: center;
  }
  .navbar-links li a {
    padding: 0.5rem 1rem;
  }
  .navbar-links.active {
    display: flex;
  }

  /* Hero Section */
  #hero {
    display: grid;
    grid-template-columns: var(--mcol);
    grid-template-rows: repeat(5, auto);
    padding: var(--mpad);
  }
  .hero-title {
    grid-column: 2/3;
    grid-row: 1/2;
    align-self: start;
  }
  .hero-action {
    /* width: 170px; */
    align-self: center;
  }
  .icons {
    align-self: center;
    padding-top: 25px;
  }
  .fa-arrow-down {
    align-self: center;
    padding: var(--mpad);
  }
  .hero-title {
    grid-column: 2/3;
    grid-row: 1/2;
    align-self: center;
    padding: 15px 0;
  }
  .hero-text {
    grid-column: 2/3;
    grid-row: 2/3;
    padding-bottom: 15px;
  }
  .hero-logo {
    grid-column: 2/3;
    grid-row: 3/4;
    align-self: center;
    justify-self: center;
  }
  .hero-action {
    grid-column: 2/3;
    grid-row: 4/5;
    justify-self: center;
    padding-top: 10px;
  }
  .icons {
    padding-top: 25px;
  }
  .fa-arrow-down {
    grid-column: 2/3;
    grid-row: 5/6;
    justify-self: center;
    align-self: center;
    padding-top: 50px;
  }
  i.fab {
    display: inline-block;
    border-radius: 60px;
    box-shadow: 0px 0px 2px #888;
    padding: 0.5em 0.6em;
  }
  i.fab:hover {
    color: var(--sec-c);
  }
  .icon {
    margin-left: 5px;
    margin-right: 5px;
  }

  /* About Section */
  #about {
    grid-template-columns: var(--mcol);
    grid-template-rows: repeat(4, auto);
    padding: var(--mpad);
  }
  .about-title {
    grid-column: 2/3;
    grid-row: 1/2;
    margin-top: 0;
  }
  .about-text {
    grid-column: 2/3;
    grid-row: 2/3;
    align-self: center;
  }
  .about-action {
    grid-column: 2/3;
    grid-row: 3/4;
    align-self: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
  }
  .action-web {
    display: none;
  }
  .action-mob {
    display: block;
  }

  .about-image {
    grid-column: 2/3;
    grid-row: 4/5;
    align-self: center;
  }

  /* Testimony Section */
  .testimony {
    background: var(--c-background);
    display: grid;
    grid-template-columns: var(--mcol);
    grid-template-rows: repeat(4, auto);
    padding: var(--wpad);
  }
  .test-title {
    color: var(--sec-t);
    grid-column: 2/3;
    grid-row: 1/2;
  }
  .test-text {
    color: var(--pri-t);
    grid-column: 2/3;
    grid-row: 2/3;
  }
    .gallery-testimony {
    grid-column: 2/3;
    grid-row: 3/4;
	padding: 0;
	display: grid;
	grid-template-columns: repeat(10, 80vw);
	grid-template-rows: 1fr;
	grid-column-gap: 1rem;
	grid-row-gap: 1rem;
	overflow: scroll;
	height: auto;
	scroll-snap-type: both mandatory;
	scroll-padding: 1rem;
}
.client-list{
  /* width: 100%; */
}
  .test-clients {
    grid-column: 2/3;
    grid-row: 3/5;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
  .client {
    /* width: 100%; */
    display: grid;
    grid-template-columns:  60% 40%;
    grid-template-rows: 70% auto;
  }
  .client-pic {
    width: 75%;
    grid-column: 1/2;
    grid-row: 1/2;
    justify-self: center;
    align-self: center;
  }
  .client-text {
    color: var(--sec-t);
    grid-column: 2/3;
    grid-row: 1/4;
    justify-self: start;
    align-self: center;
    margin: 0;
    color: white;
    display: none;
  }
  .client-name {
    grid-column: 2/3;
    grid-row: 2/3;
    margin: 0;
    align-self: end;
    display: none;
  }
  .client-company {
    grid-column: 1/2;
    grid-row: 2/3;
    align-self: center;
    justify-self: center;
    margin: 0;
  }
  .c-top,
  .c-bottom {
    padding: 25px 0 25px 0;
  }
  .client-web {
    display: none;
  }
  .client-mob {
    display: block;
    grid-column: 2/3;
    grid-row: 1/3;
  }
  /* Features Section */
  .feature {
    grid-template-columns: var(--mcol);
    grid-template-rows: repeat(5, auto);
    padding: var(--mpad);
    row-gap: 25px;
  }
  .feat-title {
    grid-column: 2/3;
    grid-row: 1/2;
    justify-self: start;
    align-self: center;
    margin: 0;
  }
  .feat-l {
    grid-column: 2/3;
    grid-row: 2/3;
    justify-self: center;
  }
  .feat-r {
    grid-column: 2/3;
    grid-row: 3/4;
    justify-self: center;
  }
  .feat-b {
    grid-column: 2/3;
    grid-row: 4/5;
    justify-self: center;
  }
  .feat-icons {
    grid-column: 2/3;
    grid-row: 5/6;
    align-self: center;
    display: grid;
    grid-template-rows: repeat(3, auto);
    grid-template-columns: repeat(2, auto);
    row-gap: 50px;
  }
  .iandt {
    align-self: center;
    justify-self: center;
  }
  .feat-item-text {
    text-align: center;
    margin: 0;
  }

  /* Benefits Section */
  .benefit {
    height: auto;
    grid-template-columns: var(--mcol);
    grid-template-rows: 25% 12.5% auto 12.5%;
    padding: var(--mpad);
  }
  .bene-pic {
    grid-column: 1/4;
    grid-row: 1/3;
  }
  .bene-container {
    /* Experimental only */
    height: auto;
    /* EXPERIMENTAL ONLY */
    grid-column: 2/3;
    grid-row: 2/4;
    display: grid;
    grid-template-columns: 10% auto 10%;
    grid-template-rows: 20% repeat(4, auto);
    /* justify-content: start; */
    align-items: center;
    background-color: var(--background);
    padding-bottom: 20px;
  }
  .bene {
    padding: 0;
  }
  .bene-title {
    margin: 0;
  }
  .bene-container-title {
    grid-column: 2/3;
    grid-row: 1/2;
    padding: 25px 0;
    justify-self: start;
  }
  .bene-container .item1 {
    grid-column: 2/3;
    grid-row: 2/3;
  }
  .bene-container .item2 {
    grid-column: 2/3;
    grid-row: 3/4;
  }
  .bene-container .item3 {
    grid-column: 2/3;
    grid-row: 4/5;
  }
  .bene-container .item4 {
    grid-column: 2/3;
    grid-row: 5/6;
    padding-bottom: 50px;
  }
  .bene-container p {
    align-self: center;
  }

  /* CTA Section */
  #cta {
    grid-template-columns: var(--mcol);
    grid-template-rows: repeat(4, auto);
    padding: var(--mpad);
  }
  .call-title {
    grid-column: 2/3;
    grid-row: 1/2;
    margin: 0;
  }
  .call-text {
    grid-column: 2/3;
    grid-row: 2/3;
  }

  /* here fix this for the width */
  .forms {
    grid-column: 2/3;
    grid-row: 3/4;
    grid-template-columns: 1fr;
    column-gap: 50px;
    grid-template-rows: repeat(5, 1fr);
    row-gap: 25px;
    width: 100%;
  }
  .form-input1 {
    grid-column: 1/2;
    grid-row: 1/2;
  }
  .form-input2 {
    grid-column: 1/2;
    grid-row: 2/3;
  }
  .form-input3 {
    grid-column: 1/2;
    grid-row: 3/4;
  }
  .form-input4 {
    grid-column: 1/2;
    grid-row: 4/5;
  }
  .form-button {
    grid-column: 1/2;
    grid-row: 5/6;
    /* height: 100%; */
    width: 100%;
  }

  /* Footer */
  footer {
    grid-template-columns: var(--mcol);
    grid-template-rows: repeat(5, auto);
    row-gap: 20px;
    padding: var(--mpad);
  }
  .foot1 {
    row-gap: 10px;
  }
  .footer-logo {
    justify-self: center;
  }
  .footer-link {
    justify-self: center;
  }
  .footer-icons {
    justify-self: center;
  }
  .foot2 {
    text-align: center;
    grid-column: 2/3;
    grid-row: 2/3;
    justify-self: center;
  }
  .foot3 {
    text-align: center;
    grid-column: 2/3;
    grid-row: 3/4;
    justify-self: center;
  }
  .foot4 {
    text-align: center;
    grid-column: 2/3;
    grid-row: 4/5;
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-template-rows: repeat(3, auto);
    justify-self: center;
  }
  .f4-title {
    grid-column: 1/3;
    grid-row: 1/2;
  }
  .f4-pic1 {
    grid-column: 1/2;
    grid-row: 2/3;
  }
  .f4-pic2 {
    grid-column: 2/3;
    grid-row: 2/3;
  }
  .f4-pic3 {
    grid-column: 3/4;
    grid-row: 2/3;
  }
  .f4-pic4 {
    grid-column: 1/2;
    grid-row: 3/4;
  }
  .f4-pic5 {
    grid-column: 2/3;
    grid-row: 3/4;
  }
  .f4-pic6 {
    grid-column: 3/4;
    grid-row: 3/4;
  }
  .foot-dis {
    grid-column: 2/3;
    grid-row: 5/6;
    justify-self: center;
    align-self: end;
  }
  footer h4 {
    margin-top: 0;
  }
  footer img {
    width: 50%;
  }
}

/* Mobile Landscape */
@media screen and (max-width: 768px) {
  .head-image {
    width: 16%;
    padding-left: 25px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}
/* Mobile */
@media screen and (max-width: 480px) {
  .logo {
    width: 25%;
  }

.modal-container{
  max-height: 70%;
  height: 750px;
}

  .modal-container .title {
    font-size: var(--mh3);
    font-weight: bold;
    color: var(--sec-t);
  }
  .modal-header .close-button {
    padding-left: 8px;
    padding-right: 8px;
  }
  /* .call-title, .call-text{
    justify-self: start;
  } */
  .footer-logo {
    justify-self: start;
  }
  .footer-link {
    width: 100%;
    justify-self: start;
  }
  .footer-icons {
    justify-self: start;
  }
  .foot2 {
    text-align: start;
    justify-self: start;
  }
  .foot3 {
    text-align: start;
    justify-self: start;
  }
  .foot4 {
    text-align: start;
    justify-self: start;
  }
}
